import THEME_CONFIG from '@/assets/data/theme_options.js';
/**
 * @Description: 生成主题
 * @Author: 武东超
 * @Date: 2024-08-15 17:18:45
 */
function generateColorMap(mode = 'customizable', theme) {
    let systemsType = THEME_CONFIG.filter(it => mode === it)[0];
    const themeMap = getColorMap(systemsType, mode, theme);

    const colorMap = {
        '--brand-theme': themeMap.brandTheme,
        '--header-bc-img': themeMap.headerBcImg,
        '--header-active': themeMap.headerActive,
        '--header-text': themeMap.headerText,
        '--layout-bc-img': themeMap.layoutBcImg,
        '--sild-bar-bc': themeMap.sildBarBc,
        '--sild-bar-active': themeMap.sildBarActive,
        '--sild-bar-hover': themeMap.sildBarHover
    };

    return colorMap;
};

/**
 * @Description: 获取颜色
 * @Author: 武东超
 * @Date: 2024-08-16 11:34:19
 * @param {*} systemsType
 * @param {*} mode
 * @param {*} theme
 */
function getColorMap(systemsType, mode, theme) {
    return {
        brandTheme: systemsType ? systemsType.brandTheme : theme.brandTheme,
        headerBcImg: systemsType ? systemsType.headerBcImg : theme.headerBcImg,
        headerActive: systemsType ? systemsType.headerActive : theme.headerActive,
        headerText: systemsType ? systemsType.headerText : theme.headerText,
        layoutBcImg: systemsType ? systemsType.layoutBcImg : theme.layoutBcImg,
        sildBarBc: systemsType ? systemsType.sildBarBc : theme.sildBarBc,
        sildBarActive: systemsType ? systemsType.sildBarActive : theme.sildBarActive,
        sildBarHover: systemsType ? systemsType.sildBarHover : theme.sildBarHover
    }
}

/**
 * @Description: 插入样式表
 * @Author: 武东超
 * @Date: 2024-08-16 11:41:46
 * @param {*} mode
 * @param {*} theme
 * @param {*} colorMap
 */
function insertThemeStylesheet(mode = 'customizable', theme, colorMap) {
    console.log(colorMap,'colorMap')
    const root = mode === 'customizable' 
                ? `:root[theme-color='${theme.brandTheme}'][theme-mode='customizable']`
                : `:root[theme-mode='${mode}']`;

    const styleSheet = document.createElement('style');
            styleSheet.type = 'text/css';
            styleSheet.innerText = `${root}{
                --brand-theme: ${colorMap['--brand-theme']};
                --header-bc-img: ${colorMap['--header-bc-img']};
                --header-active: ${colorMap['--header-active']};
                --header-text: ${colorMap['--header-text']};
                --layout-bc-img: ${colorMap['--layout-bc-img']};
                --sild-bar-bc: ${colorMap['--sild-bar-bc']};
                --sild-bar-active: ${colorMap['--sild-bar-active']};
                --sild-bar-hover: ${colorMap['--sild-bar-hover']};
            }`;
            
    if (mode === 'customizable') {
        document.documentElement.setAttribute('theme-color', theme.brandTheme);
        document.documentElement.setAttribute('theme-mode', mode);
    } else {
        document.documentElement.setAttribute('theme-mode', mode);
    };
    
    setTimeout(function(){
        document.head.appendChild(styleSheet)
    });
}
/**
 * @Description: 初始化主题
 * @Author: 武东超
 * @Date: 2024-08-16 09:31:53
 * @param {*} theme
 */
function initTheme(theme = THEME_CONFIG[0]) {
    const { mode } = theme;
    const colorMap = generateColorMap(mode, theme);
    insertThemeStylesheet(mode, theme, colorMap)
}

/**
 * @Description: 改变主题
 * @Author: 武东超
 * @Date: 2024-08-16 09:32:28
 * @param {*} mode
 * @param {*} theme
 */
function changeTheme(mode, theme){
    insertThemeStylesheet(mode, theme, generateColorMap(mode, theme));
}

export {
    initTheme,
    changeTheme
}