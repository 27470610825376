import Cookies from "js-cookie";
import store from "@/store";

const TokenKey = "points_store_token";
const passwordKey = "password_yxy_school";

const UserId = "points_store_userId_cloud";
const UserName = "points_store_userName_cloud";

export function getToken() {
    if (store.state.projectMark !== "") {
        return Cookies.get(
            TokenKey + store.state.projectMark.toLocaleUpperCase(),
        );
    }
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    localStorage.setItem("communicationPage", "has");
    if (store.state.projectMark !== "") {
        return Cookies.set(
            TokenKey + store.state.projectMark.toLocaleUpperCase(),
            token,
        );
    }
    return Cookies.set(TokenKey, token);
}

export function removeToken() {
    localStorage.setItem("communicationPage", "none");
    if (store.state.projectMark !== "") {
        return Cookies.remove(
            TokenKey + store.state.projectMark.toLocaleUpperCase(),
        );
    }
    return Cookies.remove(TokenKey);
}

export function getWeakPassword() {
    return Cookies.get(passwordKey);
}

export function setWeakPassword(password) {
    return Cookies.set(passwordKey, password);
}

export function removeWeakPassword() {
    return Cookies.remove(passwordKey);
}

// 以下内容 是 云校园日志需要
export function getUserId() {
    return Cookies.get(UserId);
}

export function setUserId(userId) {
    return Cookies.set(UserId, userId);
}

export function removeUserId() {
    return Cookies.remove(UserId);
}
// 以下内容 是 云校园日志需要
export function getUserName() {
    return Cookies.get(UserName);
}

export function setUserName(userName) {
    return Cookies.set(UserName, userName);
}

export function removeUserName() {
    return Cookies.remove(UserName);
}
