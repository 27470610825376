export default {
    repeatClassName (key) {
        switch (key) {
            case 0:
                return '不重复';
            case 1:
                return '每周重复';
            case 2:
                return '单周重复';
            case 3:
                return '双周重复';
            default:
                return key;
        }
    },

    isTourClassName (key) {
        switch (key) {
            case '0':
                return '未巡课';
            case '1':
                return '已巡课';
            case '2':
                return '继续巡课';
            default:
                return key;
        }
    }
}