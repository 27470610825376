// 虚拟table组件  解决el-table渲染数据过多导致页面卡顿问题
import { UTable, UTableColumn } from 'umy-ui';
// 面包屑缓存组件
import KeepAliveRouterView from '@/components/KeepAliveRouterView.vue';
console.log(KeepAliveRouterView,'KeepAliveRouterView==KeepAliveRouterView')
export default function (Vue) {
    Vue.component(UTable.name, UTable);
    Vue.component(UTableColumn.name, UTableColumn);
    Vue.component('KeepAliveRouterView', KeepAliveRouterView);
}
