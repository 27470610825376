export default {
    courseType (key) {
        switch (key) {
            case '':
                return '选择课程';
            case '1':
                return '班级课表';
            case '2':
                return '教师课表';
            case '3':
                return '年级课表';
        }
    }
}