export default {
    conversionLabel(key) {
        switch (key) {
            case 'assetsCode':
                return '资产编号';
            case 'assetsName':
                return '资产名称';
            case 'assetsUnit':
                return '计量单位';
            default:
                return key;
        }
    },

    conversionLabelMore (key) {
        switch (key) {
            case 'assetsCode':
                return '资产编号';
            case 'assetsName':
                return '资产名称';
            case 'storeName':
                return '仓库名称';
            case 'categoryName':
                return '资产品类';
            case 'assetsType':
                return '资产类型';
            case 'assetsBrand':
                return '资产品牌';
            case 'assetsModel':
                return '资产型号';
            case 'assetsSerialNumber':
                return '资产序列号';
            case 'assetsUnit':
                return '计量单位';

            case 'assetsCount':
                return '资产总数';
            case 'assetsTotal':
                return '资产总金额';
            case 'repairTimes':
                return '维修次数';
            case 'repairTotal':
                return '维修金额';
            case 'returnQuantity':
                return '归还数量';
            case 'returnTotal':
                return '归还金额';
            case 'scrapQuantity':
                return '报废数量';
            case 'scrapTotal':
                return '报废金额';
        }
    }
}
