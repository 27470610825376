import axios from 'axios';

function saveUserBehaviorRecord(url, data) {
    return axios({
        url: url,
        method: 'post',
        data: data
    }).then((res) => {
        if (res.status == '200') {
            return res.data;
        }
    }).catch((err) => {
        throw err;
    })
};


export {
    saveUserBehaviorRecord
}
