/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2023-01-10 08:56:02
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2023-01-10 14:52:59
 * @FilePath: /cloud_campus_Front-end/cloud_campus_Front-end/school_end/src/directive/permission/hasPermi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import store from '@/store'

export default {
  inserted(el, binding, vnode) {
    const {
      value
    } = binding
    const all_permission = '*:*';
    const permissions = store.state.permissions;
    if (value && value instanceof Array && value.length > 0 ) {
      const permissionFlag = value

      if ( permissions.length > 0) {
        const hasPermissions = permissions.some(permission => {
          return all_permission === permission || permissionFlag.includes(permission)
        })
  
        if (!hasPermissions) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      } else {
        console.log(el.parentNode,el)
        console.log(value,'value2');
        el.parentNode && el.parentNode.removeChild(el)
      }
      
    } else {
      console.log(`请设置操作权限标签值`)
    }
  }
}
