import vcolorpicker from 'vcolorpicker'
import xkUI from '@/xkUI/index'
import XkMessage from '@/xkUI/pages/Message/index'
// 全局按钮权限指令
import permission from '@/directive/permission'
// 检查是否省略指令
import trunced from '@/directive/trunced';
// 引入导出pdf方法
import htmlToPdf from '@/components/scrollWrapper/SummaryTeacherInfo/htmlToPdf.js'
export default function (Vue) {
    Vue.use(vcolorpicker);
    Vue.use(xkUI);
    Vue.use(XkMessage);
    Vue.use(permission);
    Vue.use(trunced);
    Vue.use(htmlToPdf)
}
