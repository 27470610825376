// 引入工具方法
import _ from 'lodash'
import moment from 'moment'
import dayjs from 'dayjs';
import { HTTP } from '@/libs/https.js';

export default function (Vue) {
    Vue.prototype._ = _;
    Vue.prototype.$moment = moment;
    Vue.prototype.dayjs = dayjs;

    Vue.prototype._net = new HTTP()
    Vue.prototype._get = function (url, data, opt) {
        return this._net.get('/api' + url, data, opt)
    }
    Vue.prototype._fet = function (url, data, opt) {
        return this._net.fet('/api' + url, data, opt)
    }
}
