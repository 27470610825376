/**
 * 中后台默认配置
 * mode 主题模式 'light', 'dark'
 * layout 导航布局 'side', 'top', 'mix'
 * brandTheme 默认主题色 '#0052D9'
 * headerBcImg 头部背景图片
 * headerActive 头部文字颜色
 * headerText 头部默认颜色
 * layoutBcImg body背景图片
 * sildBarBc 菜单背景颜色
 * sildBarActive 菜单滑入颜色
 * toolbarRightShow 工具栏在右侧
 * */
export default {
    mode: "default",
    layout: "mix",
    brandTheme: "#3C7FFF",
    headerBcImg:
        "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/default/header.png)",
    headerActive: "#FEA71D",
    headerText: "#FFFFFF",
    layoutBcImg:
        "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/default/body.png)",
    sildBarBc: "#EDF0F7",
    sildBarActive: "#3C7FFF",
    sildBarHover: "#D5D9E0",
    toolbarRightShow: true,
};
