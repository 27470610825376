import {
    saveUserBehaviorRecord
} from './server/index.js'

class EventTracking {
    get runtimeEnv () {
        return process.env.NODE_ENV;
    }

    constructor () {
        this.env = null;
        this.init();
    }

    init () {
        this.env = this.runtimeEnv;
    }
    // 行为分析
    async behavioralAnalysisServer (data) {
        if (typeof data !== 'object') {
            data = {}
        };
        let url = getBehavioralAnalysisUrl(this.env)
        await saveUserBehaviorRecord(url, data);
    }
};

function getBehavioralAnalysisUrl (env) {
    return env === 'development' ?
    'http://124.71.235.241:30060/userbehavior/common/saveUserBehaviorRecord'
    : 'https://xkfw.xinkaoyun.com:40060/prod-api/userbehavior/common/saveUserBehaviorRecord';
}

export {
    EventTracking
}
