import axios from "axios";
import store from '../store'
const browserTool = require("browser-tool");
import browserInfo from '@/libs/browserInfo.js';
import { getToken } from "@/libs/auth";
async function saveBehaviorRecord(to) {
    try {
        const getBrowserInfo = browserInfo();
        let info = await browserTool.getInfo()
        const { browser, browserVersion, system, systemVersion } = info
        const requestData = {
            viewerIP: "",
            platName: "校园端",
            platType: "PC",
            systemName: "云校园",
            systemMark: "cloud_campus",
            menuName: to.meta.title || "校园端浏览器",
            browserName: (browser || '')  + '_' + getBrowserInfo(), // 浏览器名称
            browserVersion: browserVersion || '', // 浏览器版本
            osName: system ? `${system} ${systemVersion}` : '', // 操作系统名称
            currentToken: getToken(),
            userId: store.state.userId,
            userName: store.state.userName,
        }
        const currentUrl = window.location.href;
        if (window.location.hash) {
            requestData["path"] = currentUrl;
            requestData["mode"] = "Hash";
        } else if (window.location.search) {
        } else {
            requestData["path"] = currentUrl;
            requestData["mode"] = "History";
        }

        axios({
            url: process.env.VUE_APP_XKSERVER_FEED_AGENCY_API + "/userbehavior/common/saveUserBehaviorRecord",
            method: "post",
            data: requestData
        })
    } catch (e) {
        console.log(e);
    }
};

export default saveBehaviorRecord;