var GlobalTerminal = (function (doc) {
    var Terminal = function () {}

    Terminal.prototype.init = function () {
	    this.bindEvent()
    }

    Terminal.prototype.bindEvent = function () {
	    this.handleFirefoxEvent();
    }
	Terminal.prototype.handleFirefoxEvent = function () {
		const userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
		if (userAgent.indexOf("Firefox") > -1) { //火狐浏览器需要禁掉默认的拖拽事件
			doc.body.ondrop = (e) => { //拖拽结束事件
				e.preventDefault(); //阻止浏览器默认行为，主要是为了解决火狐浏览器拖拽完打开新的窗口问题
				e.stopPropagation();
			}
		}
	}


	return Terminal
}(document))

export {
    GlobalTerminal
}
